import React from "react";

import "./ReservationWidget.scss";

function MonthRow() {
  return (
    <div className="rw-month-row">
      {new Date().toLocaleDateString("pl-PL", { month: "long" }).toUpperCase()}
    </div>
  );
}

function WeekDaysRow() {
  const weekDays = ["pn.", "wt.", "śr.", "czw.", "pt.", "sob.", "niedz."];

  const isWeekend = (dayIdx) => dayIdx > 4;

  return (
    <div className=" rw-table-row">
      {weekDays.map((day, idx) => (
        <div
          key={day}
          className={`rw-table-cell rw-week-day ${
            isWeekend(idx) && "rw-inactive"
          }`}
        >
          {day}
        </div>
      ))}
    </div>
  );
}

function DayCell({ date, currentSelection, onClick }) {
  const isPast = (date) => date.valueOf() < new Date().valueOf() - 1000;

  const isWeekend = (date) => date.getDay() === 0 || date.getDay() === 6;

  const isInactive = (date) => isPast(date) || isWeekend(date);

  const isSelected = () =>
    currentSelection && currentSelection.getDate() === date.getDate();

  const getClassName = () =>
    `rw-table-cell ${isSelected() && "rw-selected"} ${
      isInactive(date) && "rw-inactive"
    }`;

  const onConfirm = () => {
    onClick(date);
  };

  return (
    <div className={getClassName()} onClick={onConfirm}>
      {date.getDate().toString().padStart(2, "0")}
    </div>
  );
}

function DaysRow({ week, onDayChosen, currentSelection }) {
  const currentDay = (new Date().getDay() + 6) % 7; // So that Sunday is 7th

  const shiftDate = (offset) => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    return date;
  };

  return (
    <div className="rw-table-row">
      {[...Array(7).keys()].map((n) => (
        <DayCell
          key={n}
          date={shiftDate(n - currentDay + week * 7)}
          onClick={onDayChosen}
          currentSelection={currentSelection}
        />
      ))}
    </div>
  );
}

function HoursHeader() {
  return <div className="rw-hours-header">godz.</div>;
}

function HoursRow({ currentSelection, onTimeChosen }) {
  const timeStart = 9;
  const timeEnd = 17;

  return (
    <div className="rw-hours-row rw-table-row">
      {Array.from(
        Array(timeEnd - timeStart),
        (_, i) => timeStart + i
      ).map((n) =>
        n !== 11 ? (
          <div
            key={n}
            className={`rw-table-cell ${
              currentSelection === n ? "rw-selected" : ""
            }`}
            onClick={() => onTimeChosen(n)}
          >{`${n}`}</div>
        ) : null
      )}
    </div>
  );
}

class ResevationWidget extends React.Component {
  constructor(props) {
    super(props);
    this.widgetRef = React.createRef();
  }
  state = {
    /**
     * @type {Date | null}
     */
    chosenDay: null,
    /**
     * @type {number | null}
     */
    chosenTime: null,
    /**
     * @type {boolean}
     */
    isOpen: false,
    /**
     * @type {string}
     */
    choice: "",
  };

  componentDidMount() {
    window && document.addEventListener("click", this.onClickOutside);
  }

  componentWillUnmount() {
    window && document.removeEventListener("click", this.onClickOutside);
  }

  onClickOutside = (e) => {
    if (this.state.isOpen && !this.widgetRef.current.contains(e.target)) {
      this.closeWidget();
    }
  };

  canConfirm = () => this.state.chosenDay && this.state.chosenTime;

  onDayChosen = (day) => {
    this.setState({ chosenDay: day });
  };

  onTimeChosen = (time) => {
    this.setState({ chosenTime: time });
  };

  getChoice = () => {
    if (!this.canConfirm()) {
      return "";
    }

    const choice = this.state.chosenDay;
    choice.setHours(this.state.chosenTime, 0, 0, 0);

    return choice.toLocaleDateString("pl-PL", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  onConfirm = (e) => {
    e.preventDefault();
    const choice = this.getChoice();
    this.setState({ choice, isOpen: false });
    this.props.onChange({ target: { name: this.props.name, value: choice } });
  };

  openWidget = (e) => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };

  closeWidget = () => this.setState({ isOpen: false });

  isWeekend = () => new Date().getDay() === 0 || new Date().getDay() === 6;

  render() {
    console.log(this.getChoice());
    return (
      <div className="reservation-widget" ref={this.widgetRef}>
        <input
          className="reservation-widget__input"
          placeholder="Wybierz"
          name={this.props.name}
          type="text"
          readOnly
          onInput={(e) => e.preventDefault()}
          onClick={this.openWidget}
          defaultValue={this.state.choice}
          id={this.props.idName}
        />
        {this.state.isOpen && (
          <div className="rw-container">
            <WeekDaysRow />
            <div>
              <DaysRow
                week={this.isWeekend() ? 1 : 0}
                currentSelection={this.state.chosenDay}
                onDayChosen={this.onDayChosen}
              />
              <DaysRow
                week={this.isWeekend() ? 2 : 1}
                currentSelection={this.state.chosenDay}
                onDayChosen={this.onDayChosen}
              />
            </div>
            <HoursHeader />
            <HoursRow
              currentSelection={this.state.chosenTime}
              onTimeChosen={this.onTimeChosen}
            />
            <div className={"rw-confirmation"}>
              <button disabled={!this.canConfirm()} onClick={this.onConfirm}>
                Wybierz
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ResevationWidget;
