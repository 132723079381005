import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CustomCard.scss";

import { Accordion, Card } from "react-bootstrap";

class CustomCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenCard: 0,
    };
  }
  render() {
    return (
      <Accordion defaultActiveKey={1}>
        {this.props.data.map((value, index) => {
          return (
            <Card>
              <Accordion.Toggle
                onClick={() => {
                  this.state.chosenCard === index
                    ? this.setState({ chosenCard: null })
                    : this.setState({ chosenCard: index });
                }}
                className={
                  this.state.chosenCard === index ? "active-header" : null
                }
                as={Card.Header}
                eventKey={index + 1}
              >
                <i className={value.icon} />
                <p className="p-xl accordion-small-header">{value.title}</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index + 1}>
                <Card.Body>
                  <div className="info__text">
                    <p className="p-sm">{value.text}</p>
                    {value.secondtext ? (
                      <p className="p-sm">{value.secondtext}</p>
                    ) : null}
                    {value.thirdtext ? (
                      <p className="p-sm">{value.thirdtext}</p>
                    ) : null}
                    {value.fourthtext ? (
                      <p className="p-sm">{value.fourthtext}</p>
                    ) : null}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    );
  }
}

export default CustomCard;
