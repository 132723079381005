import React, { Component } from "react";
import PropTypes from "prop-types";
import Technologie from "../Technologie/Technologie";
import Integracje from "../Integracje/Integracje";
import Mozliwosci from "../Mozliwosci/Mozliwosci";
import Kontakt from "../Kontakt/Kontakt";
import Zastosowania from "../Zastosowania/Zastosowania";
import Home from "../Home/Home";
//import { Test } from './OnePage.styles';

import Header from "../../components/Header/Header";

class OnePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Header props={this.props} />

        <div className="OnePageWrapper">
          <Home />
          <Technologie />
          <Mozliwosci />
          <Integracje />
          <Zastosowania />
          <Kontakt />
        </div>
      </React.Fragment>
    );
  }
}

export default OnePage;
