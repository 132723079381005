import React, { Component } from "react";
import "./assets/scss/style.scss";

import { connect } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import Cookies from "./pages/Cookies/Cookies";

import store from "./store/index";

import { addBase } from "./actions/index";
import OnePage from "./pages/OnePage/OnePage";
// import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.props.allBase && store.dispatch(addBase(this.props.allBase));

    window &&
      window.addEventListener("scroll", (e) => {
        document.querySelector(
          ".header-section-container"
        ).style.transform = `translateY(-${window.scrollY}px)`;
      });
    this.fixEmptysHeight = () => {
      const ids = [
        "home",
        "technologie",
        "mozliwosci",
        "integracje",
        "zastosowania",
        "kontakt",
      ];
      ids.forEach((id) => {
        if (
          document.getElementById(id) &&
          document.getElementById(id + "-empty")
        ) {
          document.getElementById(id + "-empty").style.height =
            document.getElementById(id).offsetHeight + "px";
        }
      });
    };

    window.addEventListener("resize", this.fixEmptysHeight);
    this.fixEmptysHeight();
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.fixEmptysHeight();
    }, 1);

    setTimeout(() => {
      this.fixEmptysHeight();
    }, 3 * 1000);
  };

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route
              path="/cookies"
              render={(props) => <Cookies props={this.props} />}
            />
            <Route
              path="/"
              render={(props) => <OnePage props={this.props} />}
            />
          </Switch>
          <ScrollToTop />
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    /*login: state.login*/
  };
};

export default connect(mapStateToProps)(App);
