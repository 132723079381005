import React, { Component, useState } from "react";

import Layout from "../../components/Layout";
import SideInfo from "../../components/SideInfo";
import ListLink from "../../components/ListLink";
import CustomCard from "../../components/CustomCard";
import { Accordion } from "react-bootstrap";

const Technologie = (props) => {
  const linkData = [
    {
      title: "JavaScript (Node.js i React.js)",
      text: `Przestrzeń pełna technologii, która może być Twoja. Przestrzeń, którą możesz dowolnie kształtować wg swoich potrzeb. Nowoczesne rozwiązania, które stworzyliśmy z myślą o realizacji celów, jakie stawia się współczesnemu e-commerce. Otwartość środowiska na modyfikacje we wszystkich aspektach frontendu i backendu.`,
      secondtext: `SPACE - Przestrzeń, w której zrealizujesz swoje cele.`,
      icon: "lab la-js-square",
    },
    {
      title: "Kompletne, elastyczne i dwukierunkowe API",
      text: `W naszym środowisku realizujemy ideę aplikacji headless, gdzie CMS fordevelop łączony jest z niezależnym frontendem powstającym na naszej platformie.`,
      icon: "las la-arrows-alt-h",
    },
    {
      title: "Integracja Marketing Automation",
      text: `Zbieraj skutecznie leady, obsługuj je w przyjaznym środowisku manualnie i automatycznie. Pełna elastyczna integracja dostosowana do twoich potrzeb zwiększa skuteczność komunikacji i sprzedaży realizowanej z klientami pojawiającymi się w Twoim serwisie.`,
      icon: "las la-bullhorn",
    },
    {
      title: "Ready to PWA",
      text: `Technologia, dzięki której każdy serwis może stać się aplikacją dostępną na telefony Twoich Klientów. Rozwiązania PWA oszczędzają czas i pieniądze zarówno w procesie powstawania aplikacji jak i jej późniejszej obsługi.`,
      icon: "las la-mobile",
    },
    {
      title: "Auto Cache",
      text: `Zapewnij użytkownikom Twojego serwisu komfort użytkowania i user experience na najwyższym poziomie. Nasze rozwiązania gwarantują szybkość i pełne dostosowanie do środowiska mobile.`,
      icon: "las la-broom",
    },
    {
      title: "Optymalizacja zdjęć",
      text: `Jednym z wyzwań stawianych przez obecny rynek jest szybkość i jakość ładowanych się zdjęć. Jedną z dróg do tego są serwisy wykorzystujące nowoczesne formaty zdjęć. Konwertuj zdjęcia do nowoczesnego formatu WebP preferowanego przez przeglądarki, które zapewniają lepszą kompresję niż PNG czy JPEG.`,
      icon: "las la-image",
    },
    {
      title: "Server Side Rendering",
      text: `Dzięki temu rozwiązaniu:`,
      secondtext: `Strony www są przyjazne robotom indeksującym strony w wyszukiwarkach (SEO)`,
      thirdtext: `Poprawia się wydajność stron na urządzeniach mobilnych`,
      fourthtext: `Pokazujesz szybko pierwszą stronę za pomocą pierwszej zawartości (FCP) a reszta serwisu pobierana jest w tle.`,
      icon: "las la-server",
    },
  ];

  const [selected, setSelected] = useState(linkData[0]);

  return (
    <Layout
      id={
        props.showContent || props.showContent === undefined
          ? "technologie"
          : "technologie-empty"
      }
      background="star"
    >
      {props.showContent || props.showContent === undefined ? (
        <React.Fragment>
          <div className="col-4">
            <h1 className="h-3xl main-header">Technologie</h1>
            <div className="side-info-view">
              <SideInfo selected={selected} />
            </div>
          </div>
          <div className="col-6 list-link-wrapper">
            {linkData.map((data, index) => {
              return (
                <ListLink
                  onClick={() => {
                    setSelected(data);
                  }}
                  key={index}
                  active={selected.title === data.title ? "active" : null}
                  title={data.title}
                  icon={data.icon}
                />
              );
            })}
          </div>
          <div className="Accordion__container">
            <CustomCard index={"0"} data={linkData} />
          </div>
        </React.Fragment>
      ) : null}
    </Layout>
  );
};

export default Technologie;
