import { ADD_TEXT, ADD_BASE } from "../constants/action-types";

const isNode = () => {
  var isNodeBool = false;
  if (typeof process === "object") {
    if (typeof process.versions === "object") {
      if (typeof process.versions.node !== "undefined") {
        isNodeBool = true;
      }
    }
  }
  return isNodeBool;
};

let initialState = {
  texts: [],
  meta: [],
  ssr: isNode()
};

if (
  typeof window !== "undefined" &&
  typeof window.preloadedInitialState !== "undefined" &&
  window.preloadedInitialState
) {
  initialState = Object.assign({}, initialState, window.preloadedInitialState);
}

if (isNode()) {
  initialState = Object.assign({}, initialState, global.preloadedInitialState);
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEXT:
      return { ...state, texts: [...state.texts, action.newText] };
    case ADD_BASE:
      console.log("ADD_BASE ADD_BASE ADD_BASE ADD_BASE");

      return { ...state, ...action.newBase };
    default:
      return state;
  }
};

export default rootReducer;
