import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Layout from "../../components/Layout";
import CookieHeader from "../../components/CookieHeader/CookieHeader";
import "./Cookies.scss";

class Cookies extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <CookieHeader props={this.props} />
        <Layout
          id={
            this.props.showContent || this.props.showContent === undefined
              ? "home"
              : "home-empty"
          }
          background="moon"
          direction="column"
        >
          <div className="CookiesWrapper">
            <h1 className="h-md">I. DEFINICJE</h1>
            <p className="p-sm">
              1. Administrator – Affee360 spółka z o.o. z siedzibą pod adresem:
              ul. Kuropatwia 2, 51-419 Wrocław , wpisaną do rejestru
              przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
              Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, IX Wydział
              Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS:
              0000202572 , NIP: 8991069817 , REGON: 930795548
            </p>
            <p className="p-sm">
              2. Cookies – dane informatyczne, niewielkie pliki tekstowe,
              zapisywane i przechowywane na Urządzeniach, za pośrednictwem
              których Użytkownik korzysta ze strony internetowej Administratora.
            </p>
            <p className="p-sm">
              3. Urządzenie – elektroniczne urządzenie, za pośrednictwem którego
              Użytkownik uzyskuje dostęp do strony internetowej Administratora.
            </p>
            <p className="p-sm">
              4. Użytkownik – oznacza podmiot, na rzecz którego zgodnie z
              Regulaminem i przepisami prawa mogą być świadczone usługi drogą
              elektroniczną lub z którym zawarta może być Umowa o świadczenie
              usług drogą elektroniczną.
            </p>

            <h1 className="h-md">II. STOSOWANIE PLIKÓW COOKIES</h1>
            <p className="p-sm">
              1. Administrator za pośrednictwem strony internetowej wykorzystuje
              pliki Cookies.
            </p>
            <p className="p-sm">
              2. Informacje zgromadzone na podstawie plików Cookies
              wykorzystywane są w celach właściwej optymalizacji działania
              strony internetowej, a także w celach statystycznych oraz
              reklamowych.
            </p>
            <p className="p-sm">
              3. Pliki Cookies rejestrują aktywność Użytkownika strony
              internetowej rozpoznając Urządzenie, dzięki czemu strona
              internetowa wyświetlana jest w sposób zoptymalizowany do
              indywidualnych preferencji Użytkownika.
            </p>
            <p className="p-sm">
              4. Stosowane na stronie internetowej rozwiązania są bezpieczne dla
              Urządzeń Użytkowników korzystających ze strony internetowej
              Administratora. Nie jest możliwe przedostanie się do Urządzeń
              Użytkowników niebezpiecznego lub złośliwego oprogramowania.
            </p>
            <p className="p-sm">
              5. Administrator wykorzystuje dwa typy plików Cookies:
            </p>
            <p className="p-sm">
              {
                "a) Cookies sesyjne: to pliki, które są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu zakończenia sesji danej przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Urządzenia. Mechanizm Cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika."
              }
            </p>
            <p className="p-sm">
              {
                "b) Cookies trwałe: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu ich skasowania. Zakończenie sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje ich usunięcia z Urządzenia. Mechanizm Cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika."
              }
            </p>

            <h1 className="h-md">
              III. SPOSOBY OKREŚLENIA WARUNKÓW PRZECHOWYWANIA LUB UZYSKIWANIA
              DOSTĘPÓW DO COOKIES
            </h1>
            <p className="p-sm">
              1. Użytkownik ma możliwość ograniczenia lub wyłączenia dostępu
              plików Cookies do swojego Urządzenia. W przypadku skorzystania z
              tej opcji korzystanie ze strony internetowej Administratora będzie
              możliwe, poza funkcjami, które ze swojej natury wymagają plików
              Cookies.
            </p>
            <p className="p-sm">
              2. Użytkownik może samodzielnie i w każdym czasie zmienić
              ustawienia dotyczące plików Cookies, określając warunki ich
              przechowywania i uzyskiwania dostępu przez pliki Cookies do
              Urządzenia Użytkownika. Zmiany ustawień, o których mowa powyżej,
              Użytkownik może dokonać za pomocą ustawień przeglądarki
              internetowej lub za pomocą konfiguracji usługi. Ustawienia te mogą
              zostać zmienione w szczególności w taki sposób, aby blokować
              automatyczną obsługę plików Cookies w ustawieniach przeglądarki
              internetowej bądź informować o ich każdorazowym zamieszczeniu na
              Urządzeniu. Szczegółowe informacje o możliwości i sposobach
              obsługi plików Cookies dostępne są w ustawieniach oprogramowania
              (przeglądarki internetowej).
            </p>
            <p className="p-sm">
              3. Użytkownik może w każdej chwili usunąć pliki Cookies
              korzystając z dostępnych funkcji w przeglądarce internetowej,
              której używa.
            </p>

            <div className="kontakt__bar">
              <h1 className="h-xs kontakt__bar-header">
                Skontaktuj się z nami drogą mailową lub telefonicznie:
              </h1>
              <div className="kontakt__icon-text-wrapper">
                <div className="kontakt__bar-icon-circle">
                  <i class="las la-envelope-open" />
                </div>
                <a href="mailto:info@affee360.com">
                  <p className="p-sm kontakt__bar-info">info@affee360.com</p>
                </a>
              </div>
              <div className="kontakt__icon-text-wrapper">
                <div className="kontakt__bar-icon-circle">
                  <i class="las la-phone-volume" />
                </div>
                <a href="tel:+48-600-206-031">
                  <p className="p-sm kontakt__bar-info">+48 600 206 031</p>
                </a>
              </div>
            </div>
            <p className="p-sm cookies__copyright">
              Copyright © 2019 Affee 360. All Right Reserved. Zobacz politykę
              prywatności.
            </p>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Cookies;
