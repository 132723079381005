import React, { Component } from "react";
import "./ListInput.scss";
import ReservationWidget from "../ReservationWidget";

class ListInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  render() {
    return (
      <div
        className={
          this.props.textarea
            ? this.props.index % 2 !== 0
              ? "list-input textarea second-col"
              : "list-input textarea"
            : this.props.index % 2 !== 0
            ? "list-input second-col"
            : "list-input"
        }
      >
        <p className="p-xs list-input__header">{this.props.header}</p>
        {this.props.dropdown ? (
          <ReservationWidget
            name="timePreference"
            placeholder="Preferowany czas kontaktu"
            onChange={(e) => this.props.handleChange(e, this.props.idName)}
            idName={this.props.idName}
            value={this.state.value}
          />
        ) : this.props.textarea ? (
          <textarea
            className="list-input__content"
            placeholder={this.props.placeholder}
            onChange={(e) => this.props.handleChange(e, this.props.idName)}
          />
        ) : (
          <input
            className="list-input__input"
            placeholder={this.props.placeholder}
            onChange={(e) => this.props.handleChange(e, this.props.idName)}
          />
        )}
      </div>
    );
  }
}

export default ListInput;
