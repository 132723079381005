import React, { useState } from "react";

import Layout from "../../components/Layout";
import SideInfo from "../../components/SideInfo";
import ListLink from "../../components/ListLink";
import CustomCard from "../../components/CustomCard";
import "./Zastosowania.scss";
import { Accordion } from "react-bootstrap";

const Zastosowania = (props) => {
  const linkData = [
    {
      title: "Generowanie leadów",
      text: `Wszędzie tam, gdzie istotny jest proces optymalizacji pozyskiwania leadów i płynna obsługa spływających zapytań. Uszczelnienie strony i efektywne zwiększenie konwersji na lead, dzięki aktywnej integracji narzędziem marketing automation.`,
      icon: "las la-bullhorn",
    },
    {
      title: "Serwisy e-commerce",
      text: `Dla zaawansowanych platform sprzedażowych z rozwiniętym backendem i multi integracjami. Nasze API zapewnia elastyczne podejście do zarządzania produktem/ofertą, marketingiem i obsługą zamówień w jednym miejscu.`,
      icon: "las la-exchange-alt",
    },
    {
      title: "Koszyki zakupowe",
      text: `Dla katalogów, stron bez funkcji e-commerce, pojedynczych produktów itp. zapewniamy cały flow koszyka i płatności, a dzięki integracji z fordevelop obsługę transakcji, analitykę sprzedaży oraz e-mail/sms marketingu.`,
      icon: "las la-project-diagram",
    },
  ];

  return (
    <Layout
      id={
        props.showContent || props.showContent === undefined
          ? "zastosowania"
          : "zastosowania-empty"
      }
      direction={"column"}
      background="lightbulb"
    >
      {props.showContent || props.showContent === undefined ? (
        <React.Fragment>
          <h1 className="h-3xl usage__header">Zastosowania</h1>
          <div className="usage__container">
            <div className="col-4">
              <SideInfo usage={1} selected={linkData[0]} />
            </div>
            <div className="col-4">
              <SideInfo usage={1} selected={linkData[1]} />
            </div>
            <div className="col-4">
              <SideInfo usage={1} selected={linkData[2]} />
            </div>
          </div>
          <div className="Accordion__container">
            <CustomCard index={"3"} data={linkData} />
          </div>
        </React.Fragment>
      ) : null}
    </Layout>
  );
};

export default Zastosowania;
