import React, { useState } from "react";

import Layout from "../../components/Layout";
import SideInfo from "../../components/SideInfo";
import ListLink from "../../components/ListLink";
import CustomCard from "../../components/CustomCard";
import { Accordion } from "react-bootstrap";

import "./Integracje.scss";

const Integracje = (props) => {
  const linkData = [
    {
      title: "Płatności",
      text: `Możliwość skutecznej i efektywnej komunikacji dwukierunkowej z dostawcami usług, partnerami czy naszymi wewnętrznymi systemami jest kluczowa dla realizacji skutecznej i efektywnej sprzedaży i obsługi klienta.`,
      secondtext: `Możliwość integracji z każdą dowolną zewnętrzną usługą, z której potrzebujesz w swoim sklepie lub do której musisz przesłać dane.`,
      icon: "las la-credit-card",
    },
    {
      title: "Kurierzy",
      text: `Możliwość zskutecznej i efektywnej komunikacji dwukierunkowej z dostawcami usług, partnerami czy naszymi wewnętrznymi systemami jest kluczowa dla realizocji skutecznej i efektywnej sprzedazy i obsługi klienta.`,
      secondtext: `Możliwość integracji z każdą dowolną zewnętrzną usługa z której danych potrzebujesz w swoim sklepie lub do której musisz przesłać dane.`,
      icon: "las la-shipping-fast",
    },
    {
      title: "Systemy ERP",
      text: `Możliwość zskutecznej i efektywnej komunikacji dwukierunkowej z dostawcami usług, partnerami czy naszymi wewnętrznymi systemami jest kluczowa dla realizocji skutecznej i efektywnej sprzedazy i obsługi klienta.`,
      secondtext: `Możliwość integracji z każdą dowolną zewnętrzną usługa z której danych potrzebujesz w swoim sklepie lub do której musisz przesłać dane.`,
      icon: "las la-server",
    },
    {
      title: "Systemy księgowe",
      text: `Możliwość zskutecznej i efektywnej komunikacji dwukierunkowej z dostawcami usług, partnerami czy naszymi wewnętrznymi systemami jest kluczowa dla realizocji skutecznej i efektywnej sprzedazy i obsługi klienta.`,
      secondtext: `Możliwość integracji z każdą dowolną zewnętrzną usługa z której danych potrzebujesz w swoim sklepie lub do której musisz przesłać dane.`,
      icon: "las la-file-alt",
    },
    {
      title: "Dropshiping",
      text: `Możliwość zskutecznej i efektywnej komunikacji dwukierunkowej z dostawcami usług, partnerami czy naszymi wewnętrznymi systemami jest kluczowa dla realizocji skutecznej i efektywnej sprzedazy i obsługi klienta.`,
      secondtext: `Możliwość integracji z każdą dowolną zewnętrzną usługa z której danych potrzebujesz w swoim sklepie lub do której musisz przesłać dane.`,
      icon: "las la-box",
    },
  ];

  const [selected, setSelected] = useState(linkData[0]);
  return (
    <Layout
      id={
        props.showContent || props.showContent === undefined
          ? "integracje"
          : "integracje-empty"
      }
      background="ship"
    >
      {props.showContent || props.showContent === undefined ? (
        <React.Fragment>
          <div className="col-4">
            <h1 className="h-3xl main-header">Integracje</h1>
            <div className=" side-info-view">
              <SideInfo selected={selected} />
            </div>
          </div>
          <div className="col-6 list-link-wrapper">
            {linkData.map((data, index) => {
              return (
                <ListLink
                  onClick={() => {
                    setSelected(data);
                  }}
                  key={index}
                  active={selected.title === data.title ? "active" : null}
                  title={data.title}
                  icon={data.icon}
                />
              );
            })}
          </div>
          <div className="Accordion__container">
            <CustomCard index={"2"} data={linkData} />
          </div>
        </React.Fragment>
      ) : null}
    </Layout>
  );
};

export default Integracje;
