import React, { Component } from "react";
import "./ListLink.scss";

class ListLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`list-link ${this.props.active ? this.props.active : ""}`}
          onClick={this.props.onClick}
        >
          <i className={this.props.icon} />
          <h1 className="h-xl list-link__text">{this.props.title}</h1>
        </div>
      </React.Fragment>
    );
  }
}

export default ListLink;
