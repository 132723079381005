import React from "react";
import "./SideInfo.scss";

import Button from "../../components/Button";
import { NavLink } from "react-router-dom";

let scrollToElement = (element) => {
  window.scrollTo({
    top: document.getElementById(element).offsetTop,
    left: 0,
    behavior: "smooth",
  });
};
const SideInfo = (props) => {
  const selected = props.selected;

  return (
    <React.Fragment>
      <span className="info__icon">
        <i className={selected.icon} />
      </span>
      <h4 className="h-md info__title">{selected.title}</h4>
      {props.usage ? (
        <p className="p-lg info__text-usage">{selected.text}</p>
      ) : (
        <div className="info__text">
          <p className="p-sm">{selected.text}</p>
          {selected.secondtext ? (
            <p className="p-sm">{selected.secondtext}</p>
          ) : null}
          {selected.thirdtext ? (
            <p className="p-sm">{selected.thirdtext}</p>
          ) : null}
          {selected.fourthtext ? (
            <p className="p-sm">{selected.fourthtext}</p>
          ) : null}
        </div>
      )}
      {props.usage ? null : (
        <div onClick={() => scrollToElement("kontakt")}>
          <Button buttonStyle="filled" title="Dowiedz się więcej" />
        </div>
      )}
    </React.Fragment>
  );
};

export default SideInfo;
