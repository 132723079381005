import React, { useState, Component } from "react";

import Layout from "../../components/Layout";
import SideInfo from "../../components/SideInfo";
import ListLink from "../../components/ListLink";
import CustomCard from "../../components/CustomCard";
import { Accordion } from "react-bootstrap";

const Mozliwosci = (props) => {
  const linkData = [
    {
      title: "Marketing Automation",
      text: `Zbieraj i analizuj dane kontaktowe. Integruj i generuj formularze. Przygotowuj automatyczne kampanie e-mail i sms. Wykorzystuj swój serwis do spersonalizowanej komunikacji ze swoimi klientami, dzięki danym zebranym w naszym narzędziu Fordevelop lub innym wykorzystywanym przez Ciebie narzędziu marketing automation.`,
      icon: "las la-bullhorn",
    },
    {
      title: "Personalizacja treści",
      text: `Identyfikuj użytkowników z użyciem technologii cookies i local storage, które pozwalają przechowywać i identyfikować użytkowników oraz personalizować przekazywane im treści.`,
      icon: "las la-user-circle",
    },
    {
      title: "SEO",
      text: `Nasze środowisko wspiera SEO poprzez server side rendering, ale też przy użyciu takich elementów jak metadane podstron czy nowoczesny system generowania mapy strony.`,
      icon: "las la-search",
    },
    {
      title: "Wielojęzyczność",
      text: `Rozwiązuj problemy wielojęzycznych serwisów dzięki dedykowanym dla wszystkich języków treściom, adresom url, metadanym, jak również wsparciem rozpoznawania lokalizacji i języka użytkownika, tak by trafiał na najlepszą dla niego wersję od samego początku.`,
      icon: "las la-language",
    },
    {
      title: "Obsługa transakcji",
      text: `Niezależnie od tego jak zdefiniujesz deal w swoim serwisie, w naszym systemie znajdziemy sposób, by go odwzorować i przygotować model pozyskiwania i obsługi Twojego produktu/usługi, który zapewni Ci skuteczność.`,
      icon: "las la-exchange-alt",
    },
    {
      title: "Wielokanałowość sprzedaży",
      text: `Chcesz być tam, gdzie Twoi klienci? 
      Potrzebujesz specjalnej aplikacji lub integracji z konkretnym marketplace? 
      Może szukasz możliwości prezentacji produktów i realizacji sprzedaży w social mediach? 
      Nasze środowisko jest otwarte na każdy rodzaj integracji zewnętrznej i modyfikacji, która zbliży Cię do Twojego klienta i sprzedaży.`,
      icon: "las la-project-diagram",
    },
    {
      title: "Moduły marketingowe",
      text: `Zintegruj z CMS narzędzia wspierające sprzedaż - kupony rabatowe, progi rabatowe, narzędzia wspierające systemy partnerskie.`,
      secondtext: `Stworzyliśmy przestrzeń, w której potrzeby marketingu są realizowane równolegle. To marketing generuje sprzedaż i daje możliwość rozwoju, a technologia musi go wspierać. Zadania realizujemy z serca systemu, które są jego integralną częścią, a nie chwilowo kompatybilną wtyczką.`,
      thirdtext: `Sprawna obsługa, promocje, komunikacja z klientem, SEO są dla sklepu tak samo ważne jak dobra integracja z płatnościami czy kurierem.`,
      icon: "las la-stream",
    },
  ];

  const [selected, setSelected] = useState(linkData[0]);
  return (
    <Layout
      id={
        props.showContent || props.showContent === undefined
          ? "mozliwosci"
          : "mozliwosci-empty"
      }
      background="lightbulb"
    >
      {props.showContent || props.showContent === undefined ? (
        <React.Fragment>
          <div className="col-4">
            <h1 className="h-3xl main-header">Możliwości</h1>
            <div className="side-info-view">
              <SideInfo selected={selected} />
            </div>
          </div>
          <div className="col-6 list-link-wrapper">
            {linkData.map((data, index) => {
              return (
                <ListLink
                  onClick={() => {
                    setSelected(data);
                  }}
                  key={index}
                  active={selected.title === data.title ? "active" : null}
                  title={data.title}
                  icon={data.icon}
                />
              );
            })}
          </div>
          <div className="Accordion__container">
            <CustomCard index={"1"} data={linkData} />
          </div>
        </React.Fragment>
      ) : null}
    </Layout>
  );
};
export default Mozliwosci;
