import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import check from "../../assets/images/check.svg";

import "./Home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  scrollToElement = (element) => {
    window.scrollTo({
      top: document.getElementById(element).offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <Layout
        id={
          this.props.showContent || this.props.showContent === undefined
            ? "home"
            : "home-empty"
        }
        background="moon"
      >
        {this.props.showContent || this.props.showContent === undefined ? (
          <main className="container">
            <h1 className="h-2xl title">
              By rosnąć, biznes potrzebuje przestrzeni. <br />
              Nowe miejsce <strong>Twojego e-commerce.</strong>
            </h1>
            <h3 className="h-md subtitle">Nie ma rzeczy niemożliwych.</h3>
            <table className="table col-8">
              <tr className="table__row col-6">
                <td className="table__cell">
                  <img src={check} className="table__check" alt="red check" />
                  <h3 className="h-xs cell-text">Szybkość</h3>
                </td>
                <td className="table__cell">
                  <img src={check} className="table__check" alt="red check" />
                  <h3 className="h-xs cell-text">Skalowalność</h3>
                </td>
              </tr>
              <tr className="table__row col-6">
                <td className="table__cell">
                  <img src={check} className="table__check" alt="red check" />
                  <h3 className="h-xs cell-text">Elastyczność</h3>
                </td>
                <td className="table__cell">
                  <img src={check} className="table__check" alt="red check" />
                  <h3 className="h-xs cell-text">Stabilność</h3>
                </td>
              </tr>
            </table>
            <div className="buttons-container col-4">
              <div onClick={() => this.scrollToElement("technologie")}>
                <Button
                  onclickValue=".technologie"
                  buttonStyle="filled"
                  title="Więcej"
                />
              </div>
              <div onClick={() => this.scrollToElement("kontakt")}>
                <Button
                  onclickValue=".kontakt"
                  buttonStyle="outlined"
                  title="Kontakt"
                />
              </div>
            </div>
          </main>
        ) : null}
      </Layout>
    );
  }
}
export default Home;
