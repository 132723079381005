import React, { Component } from "react";

import Layout from "../../components/Layout";
import SideInfo from "../../components/SideInfo";
import ListInput from "../../components/ListInput";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "../../components/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import "./Kontakt.scss";
import { NavLink } from "react-router-dom";

class Kontakt extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    consent: 0,
    messageValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      calendar: "",
      companyname: "",
      message: "",
    },
  });

  resetState = () => {
    this.setState(this.getInitialState(), () => {
      console.log(this.state);
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let errorValues = [];
    let errorMessage = "";
    let messageCorrect = 1;
    console.log(this.state);
    if (this.state.consent === 0) {
      messageCorrect = 0;
      NotificationManager.error("Musisz zaakceptować zgodę");
    } else {
      if (this.state.messageValues.firstName === "") {
        errorValues.push("imię");
      }
      if (this.state.messageValues.lastName === "") {
        errorValues.push("nazwisko");
      }
      if (this.state.messageValues.email === "") {
        errorValues.push("e-mail");
      }
      if (this.state.messageValues.phone === "") {
        errorValues.push("telefon");
      }
      if (this.state.messageValues.calendar === "") {
        errorValues.push("preferowany czas kontaktu");
      }
      if (this.state.messageValues.message === "") {
        errorValues.push("wiadomość");
      }
      if (this.state.messageValues.companyname === "") {
        errorValues.push("nazwa firmy lub organizacji");
      }
      if (errorValues.length > 1) {
        messageCorrect = 0;
        errorValues.map((value, index) => {
          errorMessage += value;
          if (index < errorValues.length - 1) {
            errorMessage += ", ";
          }
        });
        NotificationManager.error("Pola " + errorValues + " są puste.");
      }

      if (errorValues.length === 1) {
        messageCorrect = 0;
        errorMessage = errorValues[0];
        NotificationManager.error("Pole " + errorValues + " jest puste.");
      }
    }
    if (messageCorrect === 1) {
      // const formData = new FormData();
      // formData = this.state.messageValues;
      // console.log(formData);
      const data = {
        firstName: this.state.messageValues.firstName,
        lastName: this.state.messageValues.lastName,
        email: this.state.messageValues.email,
        phone: this.state.messageValues.phone,
        // calendar: this.state.messageValues.calendar,
        // companyname: this.state.messageValues.companyname,
        message:
          this.state.messageValues.companyname +
          " " +
          this.state.messageValues.calendar +
          " " +
          this.state.messageValues.message,
      };
      console.log(data);
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "/contact-form", true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(data));
      xhr.onload = () => {
        let res = JSON.parse(xhr.responseText);

        if ("ok" in res && res.ok) {
          NotificationManager.success("Formularz został wysłany.");
        } else {
          NotificationManager.error(
            "Wystąpił błąd podczas wysyłania formularza."
          );
        }
      };

      errorValues = [];
      errorMessage = "";
      messageCorrect = 1;
      this.resetState();
      //event.target.reset();
      [...event.target.querySelectorAll("input, textarea")].forEach((input) => {
        input.value = "";
      });
      // var calendarValue = document.getElementById("calendar");
      // calendarValue.value = "";
    }
    this.setState({ consent: 0 });
  };

  handleChange = (event, name) => {
    this.setState(
      {
        messageValues: {
          ...this.state.messageValues,
          [name]: event.target.value,
        },
      },
      () => {
        console.log("wartosci", this.state.messageValues);
      }
    );
  };

  render() {
    const linkData = [
      {
        header: "Imię:",
        placeholder: `Twoje imię`,
        idName: "firstName",
      },
      {
        header: "Nazwisko:",
        placeholder: `Twoje nazwisko`,
        idName: "lastName",
      },
      {
        header: "E-mail:",
        placeholder: `Twój e-mail`,
        idName: "email",
      },
      {
        header: "Telefon:",
        placeholder: `Wpisz numer telefonu`,
        idName: "phone",
      },
      {
        header: "Preferowany czas kontaktu:",
        placeholder: `Wybierz`,
        dropdown: 1,
        idName: "calendar",
      },
      {
        header: "Nazwa firmy lub organizacji:",
        placeholder: `Uzupełnij`,
        idName: "companyname",
      },
      {
        header: "Wiadomość:",
        placeholder: `Szanowni Państwo...`,
        textarea: 1,
        idName: "message",
      },
    ];
    return (
      <Layout
        id={
          this.props.showContent || this.props.showContent === undefined
            ? "kontakt"
            : "kontakt-empty"
        }
        direction="column"
        center="center"
        background="door"
      >
        {this.props.showContent || this.props.showContent === undefined ? (
          <div className="kontakt__main-container">
            <form
              className="col-6 list-input-wrapper"
              onSubmit={this.handleSubmit}
            >
              <h2 className="h-lg kontakt__header">Skontaktuj się z nami</h2>
              <span className="p-sm kontakt__small-header">
                Chcesz wiedzieć więcej: zostaw wiadomość - skontaktujemy się
                niebawem.
              </span>
              {linkData.map((data, index) => {
                return (
                  <ListInput
                    header={data.header}
                    placeholder={data.placeholder}
                    dropdown={data.dropdown}
                    textarea={data.textarea}
                    idName={data.idName}
                    value={this.state.value}
                    checkbox={data.checkbox}
                    description={data.description}
                    handleChange={this.handleChange}
                    index={index}
                  />
                );
              })}
              <div className="kontakt__checkbox-container">
                <div
                  className={
                    this.state.consent
                      ? "kontakt__custom-checkbox active"
                      : "kontakt__custom-checkbox"
                  }
                  onClick={() => {
                    this.setState({ consent: !this.state.consent });
                  }}
                />
                <span className="p-2xs kontakt__checkbox-description">
                  Wyrażam zgodę na gromadzenie, przetwarzanie i wykorzystanie
                  moich danych osobowych przez firmę Affee360 Sp. z o.o.
                </span>
              </div>
              <div className="kontakt__button-container">
                <Button buttonStyle="filled--red" title="Wyślij wiadomość" />
              </div>
              <NotificationContainer />
            </form>
            <div className="kontakt__bar">
              <h1 className="h-xs kontakt__bar-header">
                Skontaktuj się z nami drogą mailową lub telefonicznie:
              </h1>
              <div className="kontakt__icon-text-wrapper">
                <div className="kontakt__bar-icon-circle">
                  <i class="las la-envelope-open" />
                </div>
                <a href="mailto:info@affee360.com">
                  <p className="p-sm kontakt__bar-info">info@affee360.com</p>
                </a>
              </div>
              <div className="kontakt__icon-text-wrapper">
                <div className="kontakt__bar-icon-circle">
                  <i class="las la-phone-volume" />
                </div>
                <a href="tel:+48-600-206-031">
                  <p className="p-sm kontakt__bar-info">+48 600 206 031</p>
                </a>
              </div>
            </div>
            <p className="p-sm kontakt__copyright">
              Copyright © 2019 Affee 360. All Right Reserved. Zobacz
              <NavLink to={"/cookies"} className="cookie-link">
                <span className="cookie-link-text">politykę prywatności.</span>
              </NavLink>
            </p>
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default Kontakt;
