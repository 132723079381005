import React, { Component } from "react";

import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import Technologie from "../../pages/Technologie/Technologie";
import Integracje from "../../pages/Integracje/Integracje";
import Mozliwosci from "../../pages/Mozliwosci/Mozliwosci";
import Kontakt from "../../pages/Kontakt/Kontakt";
import Zastosowania from "../../pages/Zastosowania/Zastosowania";
import Home from "../../pages/Home/Home";

import "./CookieHeader.scss";

class CookieHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonOpen: false,
    };
  }

  scrollToElement = (element) => {
    window.scrollTo({
      top: document.getElementById(element).offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <React.Fragment>
        <header className="cookie-header">
          <div className="cookie-header-content container row">
            <div className="cookie-header-left__col">
              <img src={logo} className="logo" alt="affee 360 space logo" />
            </div>
            <div className="cookie-header-right__col">
              <NavLink to="/">
                <span
                  className="cookie-header-link"
                  onClick={() =>
                    this.setState({ buttonOpen: false }, () =>
                      this.scrollToElement("technologie")
                    )
                  }
                >
                  Home
                </span>
              </NavLink>
            </div>
          </div>
          <div className="header-section-container">
            <Home showContent={false} />
          </div>
        </header>
      </React.Fragment>
    );
  }
}
export default CookieHeader;
