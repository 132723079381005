import React, { Component } from "react";

import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import Technologie from "../../pages/Technologie/Technologie";
import Integracje from "../../pages/Integracje/Integracje";
import Mozliwosci from "../../pages/Mozliwosci/Mozliwosci";
import Kontakt from "../../pages/Kontakt/Kontakt";
import Zastosowania from "../../pages/Zastosowania/Zastosowania";
import Home from "../../pages/Home/Home";

import "./Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonOpen: false,
    };
  }

  scrollToElement = (element) => {
    window.scrollTo({
      top: document.getElementById(element).offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <React.Fragment>
        <header className={this.state.buttonOpen ? "header open" : "header"}>
          <div className="header-content container row">
            <div className="header-left__col">
              <img src={logo} className="logo" alt="affee 360 space logo" />
              <div
                onClick={() =>
                  this.setState({ buttonOpen: !this.state.buttonOpen })
                }
                className={
                  this.state.buttonOpen ? "menu-button-active" : "menu-button"
                }
              />
            </div>
            <div
              className={
                this.state.buttonOpen
                  ? "header-right__col"
                  : "header-right__col close"
              }
            >
              <span
                className="header-link"
                onClick={() =>
                  this.setState({ buttonOpen: false }, () =>
                    this.scrollToElement("technologie")
                  )
                }
              >
                Technologie
              </span>
              <span
                className="header-link"
                onClick={() =>
                  this.setState({ buttonOpen: false }, () =>
                    this.scrollToElement("mozliwosci")
                  )
                }
              >
                Możliwości
              </span>
              <span
                className="header-link"
                onClick={() =>
                  this.setState({ buttonOpen: false }, () =>
                    this.scrollToElement("integracje")
                  )
                }
              >
                Integracje
              </span>
              <span
                className="header-link"
                onClick={() =>
                  this.setState({ buttonOpen: false }, () =>
                    this.scrollToElement("zastosowania")
                  )
                }
              >
                Zastosowania
              </span>
              <span
                className="header-link"
                onClick={() =>
                  this.setState({ buttonOpen: false }, () =>
                    this.scrollToElement("kontakt")
                  )
                }
              >
                Kontakt
              </span>

              {/* <div className="hamburger-container">
          <span> burger </span>
        </div> */}
            </div>
          </div>
          <div className="header-section-container">
            <Home showContent={false} />
            <Technologie showContent={false} />
            <Mozliwosci showContent={false} />
            <Integracje showContent={false} />
            <Zastosowania showContent={false} />
            <Kontakt showContent={false} />
          </div>
        </header>
        {/* <div className="small-header">
          <div className="logo-button-container">
            <img src={logo} className="small-logo" alt="affee 360 space logo" />
            <i class="las la-bars" />
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}
export default Header;
